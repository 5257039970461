import { app } from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";

export default function Tab() {
  const navigate = useNavigate();

  app.getContext().then((context) => {
    const subEntityId = context.page.subPageId;
    const entityId = context.page.id;
    console.log(subEntityId,entityId);
    if(entityId === "OpenForBids") {
      navigate('/OpenForBids');
    }
    if(entityId === "PendingApproval"){
      navigate("/pendingItems");
    }
    if (subEntityId) {
      navigate(`/costitemdetails/${subEntityId}`);
    }
  });
  return <></>;
}
