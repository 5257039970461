import {
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
} from "@fluentui/react-components";
import { EditRegular } from "@fluentui/react-icons";
import { Textarea } from "@fluentui/react-components";
import { Dismiss32Filled, Checkmark32Filled } from "@fluentui/react-icons";
import { ContentViewGalleryRegular } from "@fluentui/react-icons";
import { globalStateService } from "../../../services/services";
import { DataItem, MetaField } from "../../../models/ApiResponse";
import { ActionName, Actions } from "../../../models/IActions";
import { callUrl } from "../../../services/requestService";
import ActionPayload from "../../../models/ActionPayload";

export function GenerateDynamicColumns(
  fields: MetaField[],
  showDetailsColumn: boolean,
  showCommentColumn: boolean,
  handleClick: (data: any) => void,
  onItemChanged: (item: DataItem, fieldName: string, value: any) => void
): TableColumnDefinition<DataItem>[] {
  const columns = fields.map((f) =>
    createTableColumn<DataItem>({
      columnId: f.fieldName,
      compare: (a, b) => {
        return compareValues(a, b, f);
      },
      renderHeaderCell: () => {
        return (
          <div
            style={
              f.alignment
                ? {
                    textAlign: f.alignment as React.CSSProperties["textAlign"],
                    width: "100%",
                  }
                : {}
            }
          >
            {f.displayName}
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <div
            style={
              f.alignment
                ? {
                    textAlign: f.alignment as React.CSSProperties["textAlign"],
                    width: "100%",
                  }
                : {}
            }
          >
            {getRenderCell(f, item, handleClick)}
          </div>
        );
      },
    })
  );

  if (showCommentColumn) {
    columns.push(getCommentColumn(onItemChanged));
  }

  if (showDetailsColumn) {
    columns.push(getDetailsColumn());
  }

  return columns;
}

const getRenderCell = (
  field: MetaField,
  item: DataItem,
  handleClick: (data: any) => void
) => {
  if (field.fieldName === "numCandidates") {
    return (
      <span
        style={{
          fontWeight: "bold",
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={() => {
          handleClick(
            `/candidates/${item["projectId"]?.value}/${encodeURIComponent(item["projectNum"]?.value + " - " +item["projectName"]?.value)}`
          );
        }}
      >
        {item[field.fieldName]?.displayValue || "0"}
      </span>
    );
  }

  if (field.fieldName === "link") {
    const link = item["link"]?.displayValue ? item["link"]?.displayValue : "#";
    return (
      <a
        style={{
          textOverflow: "ellipsis",
          display: "block",
          overflow: "hidden",
          width: "100%",
          whiteSpace: "nowrap",
        }}
        target="_blank"
        href={`${link}`}
        rel="noreferrer"
      >
        {item["link"]?.displayValue}
      </a>
    );
  }
  return item[field.fieldName]?.displayValue || "";
};

const compareValues = (a: DataItem, b: DataItem, field: MetaField): number => {
  const aValue = a[field.fieldName]?.value;
  const bValue = b[field.fieldName]?.value;
  // If either value is undefined or null, treat them as empty strings or zero, depending on the type.
  switch (field.dataType) {
    case "string":
      return String(aValue || "").localeCompare(String(bValue || ""));
    case "number":
      return (Number(aValue) || 0) - (Number(bValue) || 0);
    case "Date":
      return (
        (new Date(String(aValue || "")).getTime() || 0) -
        (new Date(String(bValue || "")).getTime() || 0)
      );
    default:
      return 0;
  }
};

const getDetailsColumn = () => {
  return createTableColumn<DataItem>({
    columnId: "details",
    renderHeaderCell: () => {
      return "Details";
    },
    renderCell: (item) => {
      return (
        <>
          <ContentViewGalleryRegular
            style={{ fontSize: "19px", cursor: "pointer" }}
          />
          <span
            onClick={() => {
              globalStateService.setIsPanelMaximised(false);
              globalStateService.SetCurrentCostItemId(
                item["costItemId"]?.value?.toString() || ""
              );
              globalStateService.SetCurrentCostItemComment(
                item["comment"]?.value?.toString() || ""
              );
              globalStateService.SetPanelDetailsOpen(true);
            }}
            style={{ paddingLeft: "3px", cursor: "pointer" }}
          >
            View
          </span>
        </>
      );
    },
  });
};

const getCommentColumn = (
  onItemChanged: (item: DataItem, fieldName: string, value: any) => void
) => {
  return createTableColumn<DataItem>({
    columnId: "comment",
    renderHeaderCell: () => {
      return "Comment";
    },
    renderCell: (item) => {
      const isEditing = Boolean(item["isEditingComment"]?.value);
      return isEditing ? (
        <div style={{ padding: "5px" }}>
          <Textarea
            placeholder="type here..."
            defaultValue={String(item["comment"]?.value) || ""}
            onChange={(ev, data) => onItemChanged(item, "comment", data.value)}
          />
          <div
            style={{
              width: "100%",
              columnGap: "10px",
              display: "flex",
              minWidth: "min-content",
            }}
          >
            <Checkmark32Filled
              style={{ width: "17px", cursor: "pointer", color: "green" }}
              onClick={() => {
                addComment(item);
                onItemChanged(item, "isEditingComment", false);
              }}
            />
            <Dismiss32Filled
              style={{ width: "17px", cursor: "pointer", color: "red" }}
              onClick={() => onItemChanged(item, "isEditingComment", false)}
            />
          </div>
        </div>
      ) : item["comment"].value ? (
        <span onClick={() => onItemChanged(item, "isEditingComment", true)}>
          {item["comment"]?.value}
        </span>
      ) : (
        <TableCellLayout
          onClick={() => onItemChanged(item, "isEditingComment", true)}
          media={<EditRegular />}
        >
          Add Comment
        </TableCellLayout>
      );
    },
  });
};

const addComment = (item: DataItem) => {
  const actions = item["actions"]?.properties as Actions;
  if (actions) {
    const addCommentAction = actions.activities.find(
      (a) => a.actionName === ActionName.AddComment
    );
    if (addCommentAction?.actionUrl) {
      callUrl("POST", addCommentAction?.actionUrl, "", {
        comments: item["comment"].value,
        activityType: addCommentAction.activityType,
        costItemId: item["costItemId"]?.value,
      } as ActionPayload);
    }
  }
};
