import * as React from "react";
import { makeStyles, Label, tokens, Button } from "@fluentui/react-components";
import { ActionButton } from "./ActionButton";
import useObservable from "../../hooks/useObservable";
import {
  candidateStateService,
  globalStateService,
} from "../../services/services";
import { callFunction } from "../../services/requestService";
import { DataItem, MetaField } from "../../models/ApiResponse";
import { AppContainer } from "../Shared/Container/AppContainer";
import { useNavigate, useParams } from "react-router-dom";
import { FilterFilled, FilterDismissFilled } from "@fluentui/react-icons";
import { FilterPanel } from "./Filtering/FilterPanel";
import { CandidateCard } from "./CandidateCard";
import { ArrowSort16Filled } from "@fluentui/react-icons";
import { CandidatesSortBy } from "../../models/Candidates/CandidatesSortByEnum";
import IAction from "../../models/Shared/IAction";
import { sortData } from "../../utils/sorting";
import { RequestInterviewPanel } from "./Interviews/RequestInterviewPanel";
import { InterviewsPanel } from "./Interviews/InterviewsPanel";
import apiConfig from "../../services/apiConfig";
import { getErrorMessage } from "../../utils/errorHelper";
import {
  getStoredDataByKey,
  setStoredDataByKey,
} from "../../utils/cacheHelper";

const useStyles = makeStyles({
  container: {
    gap: "18px",
    display: "flex",
    flexWrap: "wrap",
    padding: "15px",
    background: tokens.colorNeutralBackground3,
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "10px",
    width: "100%",
    margin: "10px 0 0 25px",
    "@media (min-width: 768px)": {
      // Adjust for non-mobile
      display: "flex",
      gap: "15px",
      justifyContent: "flex-end", // Align buttons to the right
      alignItems: "center",
      width: "100%",
      margin: "0",
    },
  },
  headerRoot: { width: "100%", display: "flow-root" },
  headerTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media (min-width: 768px)": {
      // On non-mobile
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
});

export interface ICandidatesProps {}

export const Candidates: React.FC<ICandidatesProps> = (props) => {
  const styles = useStyles();
  const message = useObservable(candidateStateService.message);
  const isRequestPanelOpen = useObservable(
    candidateStateService.isRequestInterviewPanelOpen
  );
  const isFilterPanelOpen = useObservable(
    candidateStateService.isFilterPanelOpen
  );
  const isInterviewsPanelOpen = useObservable(
    candidateStateService.isInterviewsPanelOpen
  );
  const [isLoading, setisLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DataItem[]>([]);
  const [meta, setMeta] = React.useState<MetaField>();
  const [updatedData, setUpdatedData] = React.useState<DataItem[]>([]);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [openIn3ssUrl, setOpenIn3ssUrl] = React.useState<string>("");
  const navigate = useNavigate();
  let { id, name } = useParams();

  React.useEffect(() => {
    setisLoading(true);

    // Fetch translations with caching
    const fetchTranslations = () => {
      const CACHE_KEY = "translations";
      const cachedData = getStoredDataByKey(CACHE_KEY);
      if (cachedData) {
        globalStateService.setTranslations(cachedData);
        return Promise.resolve(); // Return resolved promise if data is cached
      } else {
        return callFunction<any>("GET", apiConfig.candidates.pickLists)
          .then((response) => {
            globalStateService.setTranslations(response.data);
            setStoredDataByKey(CACHE_KEY, response.data);
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            candidateStateService.SetAppMessage({
              intent: "error",
              message,
            });
          });
      }
    };

    // Fetch current user data with caching
    const fetchCurrentUserData = () => {
      const CACHE_KEY = "currentUserData";
      const cachedData = getStoredDataByKey(CACHE_KEY);
      if (cachedData) {
        globalStateService.setCurrentUserData(cachedData);
        return Promise.resolve(); // Return resolved promise if data is cached
      } else {
        return callFunction<any>("GET", apiConfig.shared.me)
          .then((response) => {
            if (response.success) {
              const data = response;
              if (data) {
                globalStateService.setCurrentUserData(data);
                setStoredDataByKey(CACHE_KEY, data);
              }
            }
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            candidateStateService.SetAppMessage({
              intent: "error",
              message,
            });
          });
      }
    };

    // Auto-login request
    const autoLogin = () => {
      const requestBody = {
        module: 1,
        objectId: id,
      };
      return callFunction<any>(
        "POST",
        apiConfig.candidates.autoLogin,
        "",
        requestBody
      )
        .then((response) => {
          if (response.success) {
            setOpenIn3ssUrl(response.data);
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          candidateStateService.SetAppMessage({
            intent: "error",
            message,
          });
        });
    };

    // Fetch bids by candidate ID
    const fetchBids = () => {
      if (!id) return Promise.resolve(); // Skip if no ID is provided
      return callFunction<any>("GET", apiConfig.candidates.bids(id))
        .then((response) => {
          setData(response.data);
          setUpdatedData(response.data);
          setMeta(response.meta);
        })
        .catch(() => {
          candidateStateService.SetAppMessage({
            intent: "error",
            message: "Error fetching candidates",
          });
        });
    };

    // Run all functions in parallel
    Promise.all([
      fetchTranslations(),
      fetchCurrentUserData(),
      autoLogin(),
      fetchBids(),
    ]).finally(() => setisLoading(false));
  }, [id]);

  const openFilterPanel = () => {
    candidateStateService.SetIsFilterPanelOpen(true);
  };

  const onSortActionClick = (action: string) => {
    const sortedData = sortData(action, data);
    setUpdatedData(sortedData);
  };

  const onApplyFilters = (data: DataItem[]) => {
    setUpdatedData(data);
    setFilterApplied(true);
  };

  const onResetFilters = () => {
    setFilterApplied(false);
  };

  const openIn3ssApplication = () => {
    window.open(openIn3ssUrl, "_blank");
  };

  return (
    <AppContainer
      isLoading={isLoading}
      message={message}
      stateService={candidateStateService}
    >
      <>
        <div className={styles.headerRoot}>
          <div className={styles.headerTitle}>
            {name && (
              <div
                style={{
                  padding: message.message ? "0 0 0 25px" : "20px 0 0 25px",
                  width: "100%",
                }}
              >
                <Label size="large">Requisition: {name}</Label>
                <br />
                <Label size="medium">
                  Use the cards below to view, rate and approve/reject
                  candidates.
                </Label>
              </div>
            )}
            <div
              className={styles.wrapper}
              style={{
                padding: message.message ? "0 25px 0 0" : "20px 25px 0 0",
              }}
            >
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <Button
                  onClick={() => {
                    navigate("/openforbids");
                  }}
                >
                  Back
                </Button>
                <Button onClick={openIn3ssApplication}>
                  Open in 3SS Application
                </Button>
              </div>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <Button
                  appearance={filterApplied ? "primary" : "secondary"}
                  icon={
                    filterApplied ? <FilterDismissFilled /> : <FilterFilled />
                  }
                  onClick={openFilterPanel}
                >
                  Filter
                </Button>
                <ActionButton
                  label="Sort by"
                  icon={<ArrowSort16Filled />}
                  actions={Object.values(CandidatesSortBy).map(
                    (action) =>
                      ({
                        title: action,
                        onClick: () => {
                          onSortActionClick(action);
                        },
                      } as IAction)
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.container}>
          {updatedData.length > 0 &&
            updatedData.map((d, i) => (
              <CandidateCard key={`ca-${i}`} candidate={d} meta={meta} />
            ))}
        </div>
        {isFilterPanelOpen && (
          <FilterPanel
            data={data}
            onApplyFilters={onApplyFilters}
            meta={meta}
            onResetFilters={onResetFilters}
          />
        )}
        {isInterviewsPanelOpen && <InterviewsPanel />}
        {isRequestPanelOpen && <RequestInterviewPanel />}
      </>
    </AppContainer>
  );
};
