import { DataItem } from "../models/ApiResponse";
import { CandidatesSortBy } from "../models/Candidates/CandidatesSortByEnum";

export const parseDateAvailable = (item: DataItem, fieldName: string): Date => {
    // Assuming dateAvailable.value contains an actual date string
    const dateValue = item[fieldName].value;

    // Return the parsed date if it exists, otherwise use a distant future date
    return dateValue ? new Date(String(dateValue)) : new Date(8640000000000000);  // Max possible JS Date
};

export const sortData = (action: string, data: DataItem[]): DataItem[] => {
    switch (action) {
        case CandidatesSortBy.StartDateSoonerToLater:
          const sortedDataAscending = [...data].sort(
            (a, b) =>
              parseDateAvailable(a, "dateAvailable").getTime() -
              parseDateAvailable(b, "dateAvailable").getTime()
          );
          return sortedDataAscending;
        case CandidatesSortBy.StartDateLaterToSooner:
          const sortDataDescending = [...data].sort(
            (a, b) =>
              parseDateAvailable(b, "dateAvailable").getTime() -
              parseDateAvailable(a, "dateAvailable").getTime()
          );
          return sortDataDescending;
        case CandidatesSortBy.DateSubmittedNewestToOldest:
          const dateSubmittedDescending = [...data].sort(
            (a, b) =>
              parseDateAvailable(b, "dateCreated").getTime() -
              parseDateAvailable(a, "dateCreated").getTime()
          );
          return dateSubmittedDescending;
        case CandidatesSortBy.DateSubmittedOldestToNewest:
          const dateSubmittedAscending = [...data].sort(
            (a, b) =>
              parseDateAvailable(a, "dateCreated").getTime() -
              parseDateAvailable(b, "dateCreated").getTime()
          );
          return dateSubmittedAscending;
        case CandidatesSortBy.MyRating:
          const myRatingAscending = [...data].sort(
            (a, b) => a["bidRating"].value - b["bidRating"].value
          );
          return myRatingAscending;
        case CandidatesSortBy.RateHightToLow:
          const rateHighToLow = [...data].sort(
            (a, b) => a["payRate"].value - b["payRate"].value
          );
          return rateHighToLow;
        case CandidatesSortBy.RateLowToHigh:
          const rateLowToHigh = [...data].sort(
            (a, b) => b["payRate"].value - a["payRate"].value
          );
          return rateLowToHigh;
        case CandidatesSortBy.MatchScoreAscending:
          const matchScoreAscending = [...data].sort(
            (a, b) => handleEmptyObjects(a["systemScore"].value,b["systemScore"].value));
          return matchScoreAscending;
          case CandidatesSortBy.MatchScoreDescending:
            const matchScoreDescending = [...data].sort(
                (a, b) => handleEmptyObjects(b["systemScore"].value,a["systemScore"].value));
            return matchScoreDescending;
        default:
          return data;
      }
}

const handleEmptyObjects = (a: any, b: any) => {
    if (typeof a === 'object') return 1; // Place objects (like {}) at the end
    if (typeof b === 'object') return -1; // Place objects (like {}) at the end
    
    // If both are numbers, compare numerically
    return a - b;
}