import * as React from "react";
import {
  ComboboxProps,
  useId,
  Option,
  Dropdown,
  DropdownProps,
} from "@fluentui/react-components";
import DataProps from "../../../models/Candidates/DataProps";

export interface IDropDownControlProps {
  props?: Partial<DropdownProps>;
  defaultValue?: string;
  options: DataProps;
  placeholder?: string;
  onSelect: (data: any) => void;
}

export const DropDownControl: React.FC<IDropDownControlProps> = ({
  props,
  options,
  placeholder,
  defaultValue,
  onSelect,
}) => {
  const comboId = useId("dropdown");

  const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    onSelect(data);
  };

  return (
    <Dropdown
      aria-labelledby={comboId}
      placeholder={placeholder}
      key={comboId}
      defaultValue={defaultValue}
      onOptionSelect={onOptionSelect}
      {...props}
    >
      {options &&
        Object.entries(options).map(([key, value]) => (
          <Option key={key} value={key}>
            {value}
          </Option>
        ))}
    </Dropdown>
  );
};
