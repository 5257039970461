export const CACHE_EXPIRATION = 60 * 60 * 1000; // Cache expiration time in milliseconds (1 hour)

export const getStoredDataByKey = (CACHE_KEY: string): any => {
  const cachedData = sessionStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    const now = new Date().getTime();

    // Check if the cached data is still valid (not expired)
    if (now - timestamp < CACHE_EXPIRATION) {
      return data;
    }
  }
};

export const setStoredDataByKey = (CACHE_KEY: string, data: any) => {
  sessionStorage.setItem(
        CACHE_KEY,
        JSON.stringify({ data , timestamp: new Date().getTime() })
      );
}
