export interface Activity {
  actionName: ActionName;
  activityType: number;
  commentRequired: boolean;
  actionUrl: string;
  reasons?: { [key: string]: string };
}

export interface Actions {
  activities: Activity[];
}

export enum ActionName {
    "AddComment" = "Add Comment",
    "Reject" = "Reject",
    "Approve" = "Approve"
}