import { ApiResponse, DataItem, MetaField } from "../models/ApiResponse";

export const RemoveEmptyColumns = (response: ApiResponse) => {
  const { meta, data } = response;
  
   // Step 1: Identify columns to keep
   const columnsToKeep = Object.keys(meta).filter((fieldName) => {
    if (!meta[fieldName].visible) {
      return false;
    }

    // Check if any data entry has a non-null value for this field
    return data.some(entry => entry[fieldName]?.value);
  });

  // Step 2: Filter the meta object
  const filteredMeta = columnsToKeep.reduce((acc: { [key: string]: MetaField }, fieldName) => {
    acc[fieldName] = meta[fieldName];
    return acc;
  }, {});

  // Step 3: Filter the data entries
  const filteredData = data.map(entry => {
    const filteredEntry: DataItem = {};
    columnsToKeep.forEach(fieldName => {
      filteredEntry[fieldName] = entry[fieldName];
    });
    return filteredEntry;
  });

  return {
    ...response,
    meta: filteredMeta,
    data: filteredData,
  };
};
