import * as React from "react";
import {
  Spinner,
} from "@fluentui/react-components";
import IMessage from "../../../models/IMessage";
import { AppMessageBar } from "../MessageBar/MessageBar";

export interface IAppContainerProps {
    isLoading: boolean;
    message: IMessage;
    stateService?: any;
    setter?: any;
    children: JSX.Element;
    ignorePadding?: boolean;
}

export const AppContainer: React.FC<IAppContainerProps> = ({isLoading, message, stateService, children, setter, ignorePadding}) => {

  return (
    <div style={{width: '100%'}}>
    {isLoading ? (
      <Spinner
        style={{ marginTop: "30px" }}
        size="huge"
        label="Please wait..."
      />
    ) : (
      <>
        {message.message && (
          <div
            style={{
              padding: ignorePadding? '0' : "15px",
              width: "100%",
            }}
          >
            <AppMessageBar
              message={message}
              onDismiss={() =>
                stateService ? stateService.SetAppMessage({ message: "" }) : setter({ message: "" })
              }
            />
          </div>
        )}
        {children}
      </>
    )}
  </div>
  );
};
