import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";

export interface IAppDialogProps {
    title: string;
    content?: JSX.Element;
    actionTitle: string;
    cancelButtonTitle?: string;
    actionOnClick: (data: any) => void;
    onClose: (open: boolean) => void;
    open: boolean;
}

export const AppDialog: React.FC<IAppDialogProps> = (props) => {
  return (
    <Dialog open={props.open} onOpenChange={(_,data) => props.onClose(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            {props.content}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">{props.cancelButtonTitle ? props.cancelButtonTitle : 'Cancel'}</Button>
            </DialogTrigger>
            <Button onClick={props.actionOnClick} appearance="primary">{props.actionTitle}</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};