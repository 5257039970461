import { useEffect, useState } from "react";
import useObservable from "../../hooks/useObservable";
import { candidateStateService } from "../../services/services";
import { Button, makeStyles } from "@fluentui/react-components";
import { callFunction } from "../../services/requestService";
import { DynamicTable } from "../Shared/Table/DynamicTable";
import { AppContainer } from "../Shared/Container/AppContainer";
import apiConfig from "../../services/apiConfig";
import { getErrorMessage } from "../../utils/errorHelper";
import {
  getStoredDataByKey,
  setStoredDataByKey,
} from "../../utils/cacheHelper";
//import { getOpenForBids } from "../../services/mockService";
import { ResponsiveTable } from "../Shared/ResponsiveTable/ResponsiveTable";
const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    minWidth: "min-content",
    float: "right",
    justifyContent: "flex-end",
    width: "40%",
    "@media (max-width: 600px)": {
      width: "100%",
      float: "none",
      justifyContent: "center", // Center the button in mobile view
      marginTop: "10px", // Add spacing between text and button
    },
  },
  textContainer: {
    padding: "20px 0 0 25px",
    width: "60%",
    float: "left",
    "@media (max-width: 600px)": {
      width: "100%",
      float: "none",
      padding: "0 0 10px 0", // Adjust padding for mobile view
    },
  },
  headerRoot: {
    width: "100%",
    display: "flow-root",
  },
  tableContainer: { margin: "10px", width: "100%" },
});

export default function OpenForBids() {
  const message = useObservable(candidateStateService.message);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [translations, setTranslations] = useState<any>();
  const openForBidsItems = useObservable(
    candidateStateService.openForBidsItems
  );
  const styles = useStyles();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Adjust breakpoint as needed
    const handleResize = () => setIsMobile(mediaQuery.matches);

    handleResize(); // Check initially
    mediaQuery.addEventListener("change", handleResize); // Listen for changes

    return () => mediaQuery.removeEventListener("change", handleResize); // Cleanup on unmount
  }, []);

  useEffect(() => {
    setisLoading(true);

    // Function to fetch translations with cache handling
    const fetchTranslations = () => {
      const CACHE_KEY = "translations";
      const cachedData = getStoredDataByKey(CACHE_KEY);

      if (cachedData) {
        setTranslations(cachedData);
        return Promise.resolve(); // Return a resolved promise if data is cached
      } else {
        return callFunction<any>("GET", apiConfig.shared.translations)
          .then((response) => {
            setTranslations(response.data);
            setStoredDataByKey(CACHE_KEY, response.data);
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            candidateStateService.SetAppMessage({
              intent: "error",
              message,
            });
          });
      }
    };

    // Function to fetch open bids
    const fetchOpenForBids = () => {
      return callFunction<any>("GET", apiConfig.candidates.openForBids)
        .then((response) => {
          candidateStateService.setOpenForBidsItems(response);
          // Mock up data
          //const data = getOpenForBids();
          //candidateStateService.setOpenForBidsItems(data);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          candidateStateService.SetAppMessage({
            intent: "error",
            message,
          });
        });
    };

    // Run both requests in parallel
    Promise.all([fetchTranslations(), fetchOpenForBids()]).finally(() =>
      setisLoading(false)
    );
  }, []);

  return (
    <AppContainer
      isLoading={isLoading}
      message={message}
      stateService={candidateStateService}
    >
      {openForBidsItems?.data && openForBidsItems?.meta && (
        <div className={styles.headerRoot}>
          <div style={{ width: "100%", display: "flow-root" }}>
            <div
            className={styles.textContainer}
            style={{
              padding: message?.message ? "0 0 0 25px" : "20px 0 0 25px",
            }}
            >
              {translations?.CANDIDATE_HEADING_1 || ""}
              <br />
              The requisitions below are 'Open for Bids' and have been created
              in the last 90 days.
            </div>
            <div
              className={styles.wrapper}
              style={{
                padding: message?.message ? "0 25px 0 0" : "20px 25px 0 0",
              }}
            >
              <Button>Open in 3SS Application</Button>
            </div>
          </div>
          <div className={styles.tableContainer}>
            {isMobile ? (
              <ResponsiveTable
                data={openForBidsItems?.data || {}}
                meta={openForBidsItems?.meta || {}}
                showDetailsColumn={false}
                showCommentColumn={false}
                selectionMode="single"
                enableNavigation={true}
              />
            ) : (
              <DynamicTable
                data={openForBidsItems?.data || {}}
                meta={openForBidsItems?.meta || {}}
                showDetailsColumn={false}
                showCommentColumn={false}
                selectionMode="single"
              />
            )}
          </div>
        </div>
      )}
    </AppContainer>
  );
}
