import * as React from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import IAction from "../../models/Shared/IAction";

declare type ButtonSize = "small" | "medium" | "large";

export interface IActionButtonProps {
  label: string;
  size?: ButtonSize;
  actions?: IAction[];
  icon?: any;
  disabled?: boolean;
}

export const ActionButton: React.FC<IActionButtonProps> = ({
  label,
  size,
  icon,
  actions,
  disabled
}) => {
  return (
    <Menu >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton disabled={disabled} icon={icon} size={size || "medium"}>
          {label}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover>
        {actions && (
          <MenuList>
            {actions.map((action,index) => (
              <MenuItem key={index} onClick={action.onClick}>{action.title}</MenuItem>
            ))}
          </MenuList>
        )}
      </MenuPopover>
    </Menu>
  );
};
