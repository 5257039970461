import { Divider, Label } from "@fluentui/react-components";

export interface IFilterHeadingProps {
  label: string;
}

export const FilterHeading: React.FC<IFilterHeadingProps> = ({label}) => {
  return (
    <div style={{marginTop: '15px', marginBottom: '10px'}}>
      <Label size="large">{label}</Label>
      <Divider />
    </div>
  );
};
