import { DataItem, MetaField } from "../../../models/ApiResponse";
import { SelectionMode as SelectionMode_2 } from "@fluentui/react-utilities";
import { useNavigate } from "react-router-dom";
import { globalStateService } from "../../../services/services";
import "./ResponsiveTable.css";
import {
  Button,
  Checkbox,
  CheckboxOnChangeData,
  TableCellLayout,
  Textarea,
} from "@fluentui/react-components";
import React from "react";
import {
  Dismiss32Filled,
  Checkmark32Filled,
  EditRegular,
} from "@fluentui/react-icons";
import { ActionName, Actions } from "../../../models/IActions";
import { callUrl } from "../../../services/requestService";
import ActionPayload from "../../../models/ActionPayload";

interface ResponsiveTableProps {
  data: DataItem[];
  meta: Record<string, MetaField>;
  showDetailsColumn: boolean;
  showCommentColumn: boolean;
  selectionMode: SelectionMode_2;
  enableNavigation?: boolean;
}

export const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
  data,
  meta,
  showDetailsColumn,
  showCommentColumn,
  selectionMode,
  enableNavigation
}) => {
  const visibleColumns = Object.values(meta).filter((column) => column.visible);
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [editingItem, setEditingItem] = React.useState<string>("");
  const handleClick = (data: any) => {
    if(selectionMode === "single" && enableNavigation) {
        navigate(data);
    }    
  };

  const onItemChanged = (item: DataItem, fieldName: string, newValue: any) => {
    const ItemIndex = data.findIndex(
      (d) => d["costItemId"].value === item["costItemId"].value
    );

    if (ItemIndex >= 0) {
      //const updatedItems = items;
      const updatedItem = data[ItemIndex];
      updatedItem[`${fieldName}`].value = newValue;
      data[ItemIndex] = updatedItem;
      //setItems(updatedItems);
    }
  };

  const getCommentsColumn = (item: DataItem) => {
    return (isEditing && item["costItemId"]?.value === editingItem) ? (
      <div style={{ padding: "5px" }}>
        <Textarea
          placeholder="type here..."
          defaultValue={String(item["comment"]?.value) || ""}
          onChange={(ev, data) => onItemChanged(item, "comment", data.value)}
        />
        <div
          style={{
            width: "100%",
            columnGap: "10px",
            display: "flex",
            minWidth: "min-content",
          }}
        >
          <Checkmark32Filled
            style={{ width: "17px", cursor: "pointer", color: "green" }}
            onClick={() => {
              addComment(item);
              setIsEditing(false);
            }}
          />
          <Dismiss32Filled
            style={{ width: "17px", cursor: "pointer", color: "red" }}
            onClick={() => setIsEditing(false)}
          />
        </div>
      </div>
    ) : item["comment"].value ? (
      <span onClick={() => {setIsEditing(true);setEditingItem(item["costItemId"]?.value)}}>
        {item["comment"]?.value}
      </span>
    ) : (
      <TableCellLayout
        onClick={() => {setIsEditing(true);setEditingItem(item["costItemId"]?.value)}}
        media={<EditRegular />}
      >
        Add Comment
      </TableCellLayout>
    );
  };

  const addComment = (item: DataItem) => {
    const actions = item["actions"]?.properties as Actions;
    if (actions) {
      const addCommentAction = actions.activities.find(
        (a) => a.actionName === ActionName.AddComment
      );
      if (addCommentAction?.actionUrl) {
        callUrl("POST", addCommentAction?.actionUrl, "", {
          comments: item["comment"].value,
          activityType: addCommentAction.activityType,
          costItemId: item["costItemId"]?.value,
        } as ActionPayload);
      }
    }
  };

  const onSelectionChange = (isChecked: CheckboxOnChangeData, row:any) => {
    const updatedItems = isChecked.checked
    ? [...selectedItems, row]
    : [...selectedItems].filter(
        (r) => r["costItemId"]?.value !== row["costItemId"]?.value
      );
      setSelectedItems(updatedItems);
      globalStateService.SetSelectedItems(updatedItems);
  }

  return (
    <div className="cardContainer">
      {data.map((row, index) => (
        <div
          className={`card ${index % 2 !== 0 ? "oddRow" : ""}`} // Apply "oddRow" class to odd rows
          key={index}
        >
          {selectionMode !== "single" && (
            <div className="cardRow" key="select">
              <span className="cardLabel">Select</span>
              <span className="cardValue">
                <Checkbox
                  style={{
                    margin: 0,
                  }}
                  onChange={(_, isChecked) => onSelectionChange(isChecked, row)}
                />
              </span>
            </div>
          )}
          {visibleColumns.map((column) => (
            <div
              className="cardRow"
              key={column.fieldName}
              onClick={() =>
                handleClick(
                  `/candidates/${row["projectId"]?.value}/${encodeURIComponent(
                    row["projectNum"]?.value + " - " + row["projectName"]?.value
                  )}`
                )
              }
            >
              <span className="cardLabel">{column.displayName}:</span>
              <span className="cardValue">
                {row[column.fieldName]?.displayValue || "N/A"}
              </span>
            </div>
          ))}
          {showCommentColumn && (
            <div className="cardRow" key="comments">
              <span className="cardLabel">Comment</span>
              <span className="cardValue">{getCommentsColumn(row)}</span>
            </div>
          )}
          {showDetailsColumn && (
            <div className="cardRow" key="comments">
              <Button
                  onClick={() => {
                    globalStateService.setIsPanelMaximised(false);
                    globalStateService.SetCurrentCostItemId(
                      row["costItemId"]?.value?.toString() || ""
                    );
                    globalStateService.SetCurrentCostItemComment(
                      row["comment"]?.value?.toString() || ""
                    );
                    globalStateService.SetPanelDetailsOpen(true);
                  }}
                  icon={<Checkmark32Filled />}
                  appearance="primary"
                  className="approve-button"
                >
                  View details
                </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
