import * as React from "react";
import {
  makeStyles,
  tokens,
  Text,
  Caption1,
} from "@fluentui/react-components";

import { Card, CardHeader } from "@fluentui/react-components";
import { ApiResponse } from "../../../models/ApiResponse";

const useStyles = makeStyles({
  container: {
    gap: "16px",
    display: "flex",
    flexWrap: "wrap",
  },

  card: {
    width: "280px",
    height: "fit-content",
  },

  flex: {
    gap: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  labels: { gap: "6px" },

  footer: { gap: "12px" },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  taskCheckbox: {
    display: "flex",
    alignItems: "flex-start",
  },

  grid: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
});

export interface IHeaderCardsProps {
  headers: ApiResponse;
}

export const HeaderCards: React.FC<IHeaderCardsProps> = ({ headers }) => {
  const styles = useStyles();

  const headerColumns = Object.values(headers?.meta || {}).filter(
    (column) => column.visible
  );

  function getDescription(value: any): any {
    if(value === null || value === undefined || value === '') {
        return '-';
    }
    else {
        return value;
    }
  }

  return (
    <div className={styles.container}>
      {headerColumns.map((column) => (
        <Card className={styles.card} size="small" role="listitem">
          <CardHeader
            header={<Text weight="semibold">{column.displayName}</Text>}
            description={
              <Caption1 className={styles.caption}>
                 {getDescription(headers?.data[0][column.fieldName].displayValue)}
              </Caption1>
            }
          />
        </Card>
      ))}
    </div>
  );
};
