export enum CandidatesSortBy {
    "StartDateLaterToSooner" = "Available to Start - Later to Sooner",
    "StartDateSoonerToLater" = "Available to Start - Sooner to later",
    "DateSubmittedNewestToOldest" = "Date Submitted - Newest to Oldest",
    "DateSubmittedOldestToNewest" = "Date Submitted - Oldest to Newest",
    "MatchScoreAscending" = "Match Score - Ascending",
    "MatchScoreDescending" = "Match Score - Descending",
    "MyRating" = "My Rating of Candidates",
    "RateHightToLow" = "Rate - Hight to Low",
    "RateLowToHigh" = "Rate - Low to High",
}