import * as React from "react";
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
  useTagPickerFilter,
} from "@fluentui/react-components";
import { Tag, Avatar } from "@fluentui/react-components";
import { callFunction } from "../../../services/requestService";
import { DataItem } from "../../../models/ApiResponse";
import { candidateStateService } from "../../../services/services";
import apiConfig from "../../../services/apiConfig";

export interface InterviewPickerProps {
    bidId: string;
    onInterviewersChange: (userIds: string[]) => void;
}

export const InterviewerPicker: React.FC<InterviewPickerProps> = ({bidId,onInterviewersChange}) => {
  const [query, setQuery] = React.useState<string>("");
  const [interviewers, setInterviewers] = React.useState<DataItem[]>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  React.useEffect(()=> {
    if (bidId) {
        callFunction<any>("GET", apiConfig.candidates.additionalInterviewer(bidId))
          .then((response) => {
            setInterviewers(response.data);
          })
          .catch((error) => {
            candidateStateService.SetAppMessage({
              intent: "error",
              message: "Error fetching candidates",
            });
          })
      }
  },[bidId]);


  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-matches") {
      return;
    }
    setSelectedOptions(data.selectedOptions);
    onInterviewersChange([...data.selectedOptions].map(i=> i.split('##')[1]));
    setQuery("");
  };

  const children = useTagPickerFilter({
    query,
    options: interviewers?.length > 0 ? interviewers.map(i=> `${i["displayName"]?.displayValue}##${i['userId']?.value}`) : [],
    noOptionsElement: (
      <TagPickerOption value="no-matches">
        We couldn't find any matches
      </TagPickerOption>
    ),
    renderOption: (option) => (
      <TagPickerOption
        key={option}        
        media={
          <Avatar size={16} shape="square" aria-hidden name={option} color="colorful" />
        }
        value={option}
      >
        {option.split('##')[0]}
      </TagPickerOption>
    ),

    filter: (option) =>
      !selectedOptions.includes(option) &&
      option.toLowerCase().includes(query.toLowerCase()),
  });
  return (
      <TagPicker
        onOptionSelect={onOptionSelect}
        selectedOptions={selectedOptions}
      >
        <TagPickerControl>
          <TagPickerGroup aria-label="Selected Employees">
            {selectedOptions.map((option) => (
              <Tag
                key={option}
                shape="rounded"
                media={<Avatar aria-hidden name={option} color="colorful" />}
                value={option}
              >
                {option.split('##')[0]}
              </Tag>
            ))}
          </TagPickerGroup>
          <TagPickerInput
            aria-label="Select Employees"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </TagPickerControl>

        <TagPickerList>{children}</TagPickerList>
      </TagPicker>
  );
};