const apiEndpoints = {
  costItems: {
    pendingApproval: "costitems/pendingapproval",
    costItem: (itemId: string) => `costitems/${itemId}`,
    costItemDetails: (itemId: string) => `costitems/${itemId}/details`,
    costItemActions: (itemId: string) => `costitems/${itemId}/actions`,
  },
  candidates: {
    openForBids: "requests/openforbids",
    bids: (id: string) => `requests/${id}/bids`,
    additionalInterviewer: (bidId: string) =>
      `bids/${bidId}/interviews/additionalInterviewers`,
    pickLists: `meta/picklists?pick_list=time_zones&pick_list=interview_location_types&pick_list=interview_numbers`,
    interviews: (bidId: string) => `bids/${bidId}/interviews`,
    autoLogin: "auth/autologin",
    bid: (bidId: string) => `bids/${bidId}`
  },
  shared: {
    me: "me",
    translations: "teams/translations",
  },
};

export default apiEndpoints;
