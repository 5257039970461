import Requirement, { RequirementItem } from "../models/Candidates/Requirement";

// Function to group the requirements by 'requirementTypeId'
export const groupByRequirementType = (requirements: Requirement[]) => {
  return requirements.reduce((acc, requirement) => {
    if (!acc[requirement.requirementType.displayValue]) {
      acc[requirement.requirementType.displayValue] = [];
    }
    if(acc[requirement.requirementType.displayValue]) {
      acc[requirement.requirementType.displayValue].push(requirement.requirement);
    }
    
    return acc;
  }, {} as Record<string, RequirementItem[]>);
};
