const tssSites = [
  { id: '1231312', name: '3ss.demo', url: 'https://3ss.demo.3storysoftware.com/api/' },
  { id: '1231312', name: 'emea.support', url: 'https://hays-emea.support.3storysoftware.com/api/' }
];

export default tssSites;

/*
3SS Facelift Demo	https://facelift.demo.3storysoftware.com/api/
3SS Demo	        https://3ss.demo.3storysoftware.com/api/
HAYS EMEA Support	https://hays-emea.support.3storysoftware.com/api/

*/
