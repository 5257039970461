
import {
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  Link,
  MessageBarActions,
  Button
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import IMessage from "../../../models/IMessage";

export interface IAppMessageBarProps {
  message: IMessage;
  onDismiss: () => void;
}

export const AppMessageBar: React.FC<IAppMessageBarProps> = ({message, onDismiss}) => {
  return (
    <MessageBar intent={message.intent}>
      <MessageBarBody>
        {message.title && <MessageBarTitle>{message.title}</MessageBarTitle>}
        {message.message}
        {message.link && <Link>{message.link}</Link>}
      </MessageBarBody>
      <MessageBarActions
      containerAction={
        <Button onClick={onDismiss} appearance="transparent" icon={<DismissRegular /> }/>
      }
    >
    </MessageBarActions>
    </MessageBar>
  );
};
