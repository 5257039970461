import { Observable } from "../hooks/observable";
import IMessage from "../models/IMessage";
import { OpenForBidsSortBy } from "../models/Candidates/OpenForBidsSortByEnum";
import { ApiResponse, DataItem } from "../models/ApiResponse";

export default class CandidateStateService {
  public readonly candidateSortedBy = new Observable<OpenForBidsSortBy>(
    OpenForBidsSortBy.NewestToOldest
  );
  public readonly openForBidsItems = new Observable<ApiResponse>(
    {} as ApiResponse
  );

  public readonly message = new Observable<IMessage>({
    intent: "success",
    message: "",
  });
  public readonly selectedBid = new Observable<DataItem>({});
  public readonly candidate = new Observable<DataItem>({});
  public readonly isFilterPanelOpen = new Observable<boolean>(false);
  public readonly isRequestInterviewPanelOpen = new Observable<boolean>(false);
  public readonly isInterviewsPanelOpen = new Observable<boolean>(false);

  public resetObservables() {
    this.candidateSortedBy.set(OpenForBidsSortBy.NewestToOldest);
  }
  public setOpenForBidsItems(response: ApiResponse) {
    this.openForBidsItems.set(response);
  }
  public setCandidateSortBy(sortby: OpenForBidsSortBy) {
    this.candidateSortedBy.set(sortby);
  }
  public setSelectedBid(item: DataItem) {
    this.selectedBid.set(item);
  }
  public setCandidate(item: DataItem) {
    this.candidate.set(item);
  }
  public SetAppMessage(message: IMessage) {
    this.message.set(message);
  }
  public SetIsFilterPanelOpen(open: boolean) {
    this.isFilterPanelOpen.set(open);
  }
  public SetIsRequestInterviewPanelOpen(open: boolean) {
    this.isRequestInterviewPanelOpen.set(open);
  }
  public SetIsInterviewsPanelOpen(open: boolean) {
    this.isInterviewsPanelOpen.set(open);
  }

}
